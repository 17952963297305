import { default as _91slug_938KnEvpZ3djMeta } from "D:/W-S Projekte/WS_intern/ws-website-final-2023/pages/[slug].vue?macro=true";
import { default as _404adLVrwfdvXMeta } from "D:/W-S Projekte/WS_intern/ws-website-final-2023/pages/404.vue?macro=true";
import { default as aboutpMm9Zf3LjaMeta } from "D:/W-S Projekte/WS_intern/ws-website-final-2023/pages/about.vue?macro=true";
import { default as contactrTbtSETogYMeta } from "D:/W-S Projekte/WS_intern/ws-website-final-2023/pages/contact.vue?macro=true";
import { default as geekscon_452025Rh0LRxIvHiMeta } from "D:/W-S Projekte/WS_intern/ws-website-final-2023/pages/geekscon-2025.vue?macro=true";
import { default as index8PAxBsnL3tMeta } from "D:/W-S Projekte/WS_intern/ws-website-final-2023/pages/index.vue?macro=true";
import { default as kaleidoscope0dFt8Vp3xZMeta } from "D:/W-S Projekte/WS_intern/ws-website-final-2023/pages/kaleidoscope.vue?macro=true";
import { default as data_45privacy2lVyjEUU8wMeta } from "D:/W-S Projekte/WS_intern/ws-website-final-2023/pages/services/data-privacy.vue?macro=true";
import { default as imprintYEiBxZiDfUMeta } from "D:/W-S Projekte/WS_intern/ws-website-final-2023/pages/services/imprint.vue?macro=true";
import { default as terms_45and_45servicesZlg7Flo9s3Meta } from "D:/W-S Projekte/WS_intern/ws-website-final-2023/pages/services/terms-and-services.vue?macro=true";
import { default as sustainabilityfETX4dphbUMeta } from "D:/W-S Projekte/WS_intern/ws-website-final-2023/pages/sustainability.vue?macro=true";
import { default as urban_45brand_45spaceGGRtkMVXoKMeta } from "D:/W-S Projekte/WS_intern/ws-website-final-2023/pages/urban-brand-space.vue?macro=true";
export default [
  {
    name: _91slug_938KnEvpZ3djMeta?.name ?? "slug___de",
    path: _91slug_938KnEvpZ3djMeta?.path ?? "/de/:slug()/",
    meta: _91slug_938KnEvpZ3djMeta || {},
    alias: _91slug_938KnEvpZ3djMeta?.alias || [],
    redirect: _91slug_938KnEvpZ3djMeta?.redirect,
    component: () => import("D:/W-S Projekte/WS_intern/ws-website-final-2023/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_938KnEvpZ3djMeta?.name ?? "slug___en",
    path: _91slug_938KnEvpZ3djMeta?.path ?? "/en/:slug()/",
    meta: _91slug_938KnEvpZ3djMeta || {},
    alias: _91slug_938KnEvpZ3djMeta?.alias || [],
    redirect: _91slug_938KnEvpZ3djMeta?.redirect,
    component: () => import("D:/W-S Projekte/WS_intern/ws-website-final-2023/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _404adLVrwfdvXMeta?.name ?? "404___de",
    path: _404adLVrwfdvXMeta?.path ?? "/de/404/",
    meta: _404adLVrwfdvXMeta || {},
    alias: _404adLVrwfdvXMeta?.alias || [],
    redirect: _404adLVrwfdvXMeta?.redirect,
    component: () => import("D:/W-S Projekte/WS_intern/ws-website-final-2023/pages/404.vue").then(m => m.default || m)
  },
  {
    name: _404adLVrwfdvXMeta?.name ?? "404___en",
    path: _404adLVrwfdvXMeta?.path ?? "/en/404/",
    meta: _404adLVrwfdvXMeta || {},
    alias: _404adLVrwfdvXMeta?.alias || [],
    redirect: _404adLVrwfdvXMeta?.redirect,
    component: () => import("D:/W-S Projekte/WS_intern/ws-website-final-2023/pages/404.vue").then(m => m.default || m)
  },
  {
    name: aboutpMm9Zf3LjaMeta?.name ?? "about___de",
    path: aboutpMm9Zf3LjaMeta?.path ?? "/de/about/",
    meta: aboutpMm9Zf3LjaMeta || {},
    alias: aboutpMm9Zf3LjaMeta?.alias || [],
    redirect: aboutpMm9Zf3LjaMeta?.redirect,
    component: () => import("D:/W-S Projekte/WS_intern/ws-website-final-2023/pages/about.vue").then(m => m.default || m)
  },
  {
    name: aboutpMm9Zf3LjaMeta?.name ?? "about___en",
    path: aboutpMm9Zf3LjaMeta?.path ?? "/en/about/",
    meta: aboutpMm9Zf3LjaMeta || {},
    alias: aboutpMm9Zf3LjaMeta?.alias || [],
    redirect: aboutpMm9Zf3LjaMeta?.redirect,
    component: () => import("D:/W-S Projekte/WS_intern/ws-website-final-2023/pages/about.vue").then(m => m.default || m)
  },
  {
    name: contactrTbtSETogYMeta?.name ?? "contact___de",
    path: contactrTbtSETogYMeta?.path ?? "/de/contact/",
    meta: contactrTbtSETogYMeta || {},
    alias: contactrTbtSETogYMeta?.alias || [],
    redirect: contactrTbtSETogYMeta?.redirect,
    component: () => import("D:/W-S Projekte/WS_intern/ws-website-final-2023/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: contactrTbtSETogYMeta?.name ?? "contact___en",
    path: contactrTbtSETogYMeta?.path ?? "/en/contact/",
    meta: contactrTbtSETogYMeta || {},
    alias: contactrTbtSETogYMeta?.alias || [],
    redirect: contactrTbtSETogYMeta?.redirect,
    component: () => import("D:/W-S Projekte/WS_intern/ws-website-final-2023/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: geekscon_452025Rh0LRxIvHiMeta?.name ?? "geekscon-2025___de",
    path: geekscon_452025Rh0LRxIvHiMeta?.path ?? "/de/geekscon-2025/",
    meta: geekscon_452025Rh0LRxIvHiMeta || {},
    alias: geekscon_452025Rh0LRxIvHiMeta?.alias || [],
    redirect: geekscon_452025Rh0LRxIvHiMeta?.redirect,
    component: () => import("D:/W-S Projekte/WS_intern/ws-website-final-2023/pages/geekscon-2025.vue").then(m => m.default || m)
  },
  {
    name: geekscon_452025Rh0LRxIvHiMeta?.name ?? "geekscon-2025___en",
    path: geekscon_452025Rh0LRxIvHiMeta?.path ?? "/en/geekscon-2025/",
    meta: geekscon_452025Rh0LRxIvHiMeta || {},
    alias: geekscon_452025Rh0LRxIvHiMeta?.alias || [],
    redirect: geekscon_452025Rh0LRxIvHiMeta?.redirect,
    component: () => import("D:/W-S Projekte/WS_intern/ws-website-final-2023/pages/geekscon-2025.vue").then(m => m.default || m)
  },
  {
    name: index8PAxBsnL3tMeta?.name ?? "index___de",
    path: index8PAxBsnL3tMeta?.path ?? "/de/",
    meta: index8PAxBsnL3tMeta || {},
    alias: index8PAxBsnL3tMeta?.alias || [],
    redirect: index8PAxBsnL3tMeta?.redirect,
    component: () => import("D:/W-S Projekte/WS_intern/ws-website-final-2023/pages/index.vue").then(m => m.default || m)
  },
  {
    name: index8PAxBsnL3tMeta?.name ?? "index___en",
    path: index8PAxBsnL3tMeta?.path ?? "/en/",
    meta: index8PAxBsnL3tMeta || {},
    alias: index8PAxBsnL3tMeta?.alias || [],
    redirect: index8PAxBsnL3tMeta?.redirect,
    component: () => import("D:/W-S Projekte/WS_intern/ws-website-final-2023/pages/index.vue").then(m => m.default || m)
  },
  {
    name: kaleidoscope0dFt8Vp3xZMeta?.name ?? "kaleidoscope___de",
    path: kaleidoscope0dFt8Vp3xZMeta?.path ?? "/de/kaleidoscope/",
    meta: kaleidoscope0dFt8Vp3xZMeta || {},
    alias: kaleidoscope0dFt8Vp3xZMeta?.alias || [],
    redirect: kaleidoscope0dFt8Vp3xZMeta?.redirect,
    component: () => import("D:/W-S Projekte/WS_intern/ws-website-final-2023/pages/kaleidoscope.vue").then(m => m.default || m)
  },
  {
    name: kaleidoscope0dFt8Vp3xZMeta?.name ?? "kaleidoscope___en",
    path: kaleidoscope0dFt8Vp3xZMeta?.path ?? "/en/kaleidoscope/",
    meta: kaleidoscope0dFt8Vp3xZMeta || {},
    alias: kaleidoscope0dFt8Vp3xZMeta?.alias || [],
    redirect: kaleidoscope0dFt8Vp3xZMeta?.redirect,
    component: () => import("D:/W-S Projekte/WS_intern/ws-website-final-2023/pages/kaleidoscope.vue").then(m => m.default || m)
  },
  {
    name: data_45privacy2lVyjEUU8wMeta?.name ?? "services-data-privacy___de",
    path: data_45privacy2lVyjEUU8wMeta?.path ?? "/de/services/data-privacy/",
    meta: data_45privacy2lVyjEUU8wMeta || {},
    alias: data_45privacy2lVyjEUU8wMeta?.alias || [],
    redirect: data_45privacy2lVyjEUU8wMeta?.redirect,
    component: () => import("D:/W-S Projekte/WS_intern/ws-website-final-2023/pages/services/data-privacy.vue").then(m => m.default || m)
  },
  {
    name: data_45privacy2lVyjEUU8wMeta?.name ?? "services-data-privacy___en",
    path: data_45privacy2lVyjEUU8wMeta?.path ?? "/en/services/data-privacy/",
    meta: data_45privacy2lVyjEUU8wMeta || {},
    alias: data_45privacy2lVyjEUU8wMeta?.alias || [],
    redirect: data_45privacy2lVyjEUU8wMeta?.redirect,
    component: () => import("D:/W-S Projekte/WS_intern/ws-website-final-2023/pages/services/data-privacy.vue").then(m => m.default || m)
  },
  {
    name: imprintYEiBxZiDfUMeta?.name ?? "services-imprint___de",
    path: imprintYEiBxZiDfUMeta?.path ?? "/de/services/imprint/",
    meta: imprintYEiBxZiDfUMeta || {},
    alias: imprintYEiBxZiDfUMeta?.alias || [],
    redirect: imprintYEiBxZiDfUMeta?.redirect,
    component: () => import("D:/W-S Projekte/WS_intern/ws-website-final-2023/pages/services/imprint.vue").then(m => m.default || m)
  },
  {
    name: imprintYEiBxZiDfUMeta?.name ?? "services-imprint___en",
    path: imprintYEiBxZiDfUMeta?.path ?? "/en/services/imprint/",
    meta: imprintYEiBxZiDfUMeta || {},
    alias: imprintYEiBxZiDfUMeta?.alias || [],
    redirect: imprintYEiBxZiDfUMeta?.redirect,
    component: () => import("D:/W-S Projekte/WS_intern/ws-website-final-2023/pages/services/imprint.vue").then(m => m.default || m)
  },
  {
    name: terms_45and_45servicesZlg7Flo9s3Meta?.name ?? "services-terms-and-services___de",
    path: terms_45and_45servicesZlg7Flo9s3Meta?.path ?? "/de/services/terms-and-services/",
    meta: terms_45and_45servicesZlg7Flo9s3Meta || {},
    alias: terms_45and_45servicesZlg7Flo9s3Meta?.alias || [],
    redirect: terms_45and_45servicesZlg7Flo9s3Meta?.redirect,
    component: () => import("D:/W-S Projekte/WS_intern/ws-website-final-2023/pages/services/terms-and-services.vue").then(m => m.default || m)
  },
  {
    name: terms_45and_45servicesZlg7Flo9s3Meta?.name ?? "services-terms-and-services___en",
    path: terms_45and_45servicesZlg7Flo9s3Meta?.path ?? "/en/services/terms-and-services/",
    meta: terms_45and_45servicesZlg7Flo9s3Meta || {},
    alias: terms_45and_45servicesZlg7Flo9s3Meta?.alias || [],
    redirect: terms_45and_45servicesZlg7Flo9s3Meta?.redirect,
    component: () => import("D:/W-S Projekte/WS_intern/ws-website-final-2023/pages/services/terms-and-services.vue").then(m => m.default || m)
  },
  {
    name: sustainabilityfETX4dphbUMeta?.name ?? "sustainability___de",
    path: sustainabilityfETX4dphbUMeta?.path ?? "/de/sustainability/",
    meta: sustainabilityfETX4dphbUMeta || {},
    alias: sustainabilityfETX4dphbUMeta?.alias || [],
    redirect: sustainabilityfETX4dphbUMeta?.redirect,
    component: () => import("D:/W-S Projekte/WS_intern/ws-website-final-2023/pages/sustainability.vue").then(m => m.default || m)
  },
  {
    name: sustainabilityfETX4dphbUMeta?.name ?? "sustainability___en",
    path: sustainabilityfETX4dphbUMeta?.path ?? "/en/sustainability/",
    meta: sustainabilityfETX4dphbUMeta || {},
    alias: sustainabilityfETX4dphbUMeta?.alias || [],
    redirect: sustainabilityfETX4dphbUMeta?.redirect,
    component: () => import("D:/W-S Projekte/WS_intern/ws-website-final-2023/pages/sustainability.vue").then(m => m.default || m)
  },
  {
    name: urban_45brand_45spaceGGRtkMVXoKMeta?.name ?? "urban-brand-space___de",
    path: urban_45brand_45spaceGGRtkMVXoKMeta?.path ?? "/de/urban-brand-space/",
    meta: urban_45brand_45spaceGGRtkMVXoKMeta || {},
    alias: urban_45brand_45spaceGGRtkMVXoKMeta?.alias || [],
    redirect: urban_45brand_45spaceGGRtkMVXoKMeta?.redirect,
    component: () => import("D:/W-S Projekte/WS_intern/ws-website-final-2023/pages/urban-brand-space.vue").then(m => m.default || m)
  },
  {
    name: urban_45brand_45spaceGGRtkMVXoKMeta?.name ?? "urban-brand-space___en",
    path: urban_45brand_45spaceGGRtkMVXoKMeta?.path ?? "/en/urban-brand-space/",
    meta: urban_45brand_45spaceGGRtkMVXoKMeta || {},
    alias: urban_45brand_45spaceGGRtkMVXoKMeta?.alias || [],
    redirect: urban_45brand_45spaceGGRtkMVXoKMeta?.redirect,
    component: () => import("D:/W-S Projekte/WS_intern/ws-website-final-2023/pages/urban-brand-space.vue").then(m => m.default || m)
  }
]